<template>
  <gmap-map
    v-if="latitude && longitude"
    ref="map"
    :center="center"
    :zoom="13"
    :options="options"
    v-bind.sync="$attrs"
    v-on="$listeners"
  >
    <GmapMarker
      ref="currentLocation"
      key="current-location"
      :position="mapMarkerCoords"
      :icon="{
        url: require(`assets/markers/deviceLocation.png`)
      }"
      class="marker"
    />
    <!-- Content Type Markers -->
    <gmap-cluster>
      <gmap-marker
        v-for="(marker, key) in markers"
        :key="marker.key || key"
        v-bind="marker"
        @click="clickMarker(marker)"
      />
    </gmap-cluster>
    <!-- Other Markers -->
    <GmapMarker
      v-for="(marker, index) in components.mGmaps.markers"
      :key="`${marker.icon}-${index}`"
      :position="{ lat: marker.position.lat, lng: marker.position.lng}"
      :icon="{ url: require(`assets/markers/${marker.icon}`) }"
      class="marker"
    />
    <!-- Origin Marker -->
    <GmapMarker
      v-if="origin"
      ref="originMarker"
      :key="origin.label"
      :position="origin.latlng"
      :icon="{
        url: getIcon(origin)
      }"
      class="marker"
    />
    <!-- Destination Marker -->
    <GmapMarker
      v-if="destination"
      ref="destinationMarker"
      :key="destination.label"
      :position="destination.latlng"
      :icon="{
        url: getIcon(destination)
      }"
      class="marker"
    />
    <GmapPolyline
      v-if="routeInfo.distance.kilometres && !$route.meta.hidePolyline"
      ref="line"
      :key="routeInfo.distance.kilometres"
      :options="{ strokeColor: '#218AE5', strokeWeight: 4 }"
    />
  </gmap-map>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import { getGoogleMapsAPI } from 'gmap-vue'
import { routeInfo } from 'api/ride-hailing'

const environment = process.env.NODE_ENV

export default {
  props: {
    markers: {
      type: Array,
      default () {
        return []
      }
    },
    markerDetails: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      env: environment,
      routeInfo: {
        distance: {
          kilometres: 0
        }
      },
      options: {
        gestureHandling: 'greedy',
        draggable: true,
        clickableIcons: false,
        disableDefaultUI: true,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'transit',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      geoip: 'geoip',
      latLng: 'geolocate/latLng',
      requested: 'geolocate/requested',
      denied: 'geolocate/denied',
      center: 'map/getCenterAsJson',
      pos: 'geolocate/position',
      components: 'partner/components',
      selected: 'discovery/selected',
      origin: 'ondemand/origin',
      destination: 'ondemand/destination',
      partner: 'partner',
      selectionMode: 'ondemand/selectionMode'
    }),
    google: getGoogleMapsAPI,
    mapMarkerCoords () {
      if (this?.latLng?.latitude && this?.latLng?.longitude) return { lat: this.latLng.latitude, lng: this.latLng.longitude }
      return { lat: this.geoip.latitude, lng: this.geoip.longitude }
    },
    latitude () {
      return this?.latLng?.latitude || this.geoip.latitude
    },
    longitude () {
      return this?.latLng?.longitude || this.geoip.longitude
    }
  },
  watch: {
    markers (markers) {
      if (markers.length < 1) return
      let bounds = new window.google.maps.LatLngBounds()
      this.markers.forEach(marker => {
        bounds.extend(
          new window.google.maps.LatLng(
            marker.position.lat,
            marker.position.lng
          )
        )
      })
      this.$refs.map.fitBounds(bounds, 10)
    },
    '$route.path': {
      handler: function (path) {
        if ((path === '/home/' || path === '/home') && this.$refs.map) {
          this.$refs.map.$mapPromise.then(map => {
            map.setZoom(13)
            map.panTo({
              lat: this.latLng.latitude || this.geoip.latitude,
              lng: this.latLng.longitude || this.geoip.longitude
            })
          })
        }
      },
      deep: true,
      immediate: true
    },
    latLng: {
      deep: true,
      handler: (value) => {
        store.dispatch('map/setCenter', {
          lat: value.latitude || this.geoip.latitude,
          lng: value.longitude || this.geoip.longitude
        })
      }
    },
    selected (val) {
      if (val) {
        this.markers.filter(marker => marker.key).forEach(marker => {
          const { position, iconName, color } = marker
          let icon
          let selected
          try {
            icon = require(`assets/markers/${iconName}.svg`)
            selected = require(`assets/markers/${iconName}-selected.svg`)
          } catch (e) {
            const urlColor = color.replace('#', '%23')
            icon = `${process.env.VUE_APP_API_URL}/icon/marker/${iconName}.svg?width=24&height=36&color=${urlColor}`
            selected = `${process.env.VUE_APP_API_URL}/icon/marker/${iconName}.svg?width=24&height=36&iconColor=${urlColor}&strokeColor=${urlColor}&color=white`
          }
          if (position.lat === val.lat && position.lng === val.lng) {
            marker.icon = selected
            // require(`assets/markers/${marker.iconName}-selected.svg`)
            marker.zIndex = 2
          } else {
            marker.icon = icon
            //  require(`assets/markers/${marker.iconName}.svg`)
            marker.zIndex = 1
          }
        })
        store.dispatch('map/setCenter', {
          lat: val.lat,
          lng: val.lng
        })
      }
    },
    origin: {
      immediate: true,
      async handler (val) {
        if (!val) {
          this.routeInfo.distance.kilometres = 0
          return
        }
        if (this.destination && !this.$route.meta.hidePolyline) {
          const { data } = await routeInfo([val.latlng, this.destination.latlng])
          this.routeInfo = data
        }
        this.setBounds()
      }
    },
    destination: {
      immediate: true,
      async handler (val) {
        if (!val) {
          this.routeInfo.distance.kilometres = 0
          return
        }
        if (this.origin && !this.$route.meta.hidePolyline) {
          const { data } = await routeInfo([this.origin.latlng, val.latlng])
          this.routeInfo = data
        }
        this.setBounds()
      }
    },
    routeInfo: {
      immediate: true,
      async handler (val) {
        if (this.$route.meta.hidePolyline) return
        if (environment === 'test') return
        if (!val.distance.kilometres) return
        const polyline = val.points
        await this.$nextTick()
        const line = await this.$refs.line.$polylinePromise
        let step = 0
        let point = 0
        let numSteps = 1
        let timePerStep = 1

        line.setPath([])

        if (polyline.length < 2) return

        let departure = new this.google.maps.LatLng(polyline[step].lat, polyline[step].lng)
        let arrival = new this.google.maps.LatLng(polyline[step + 1].lat, polyline[step + 1].lng)

        function drawLine () {
          step += 1
          if (step > numSteps) {
            clearInterval(interval)
            if (point + 1 < polyline.length) {
              step = 0
              departure = new this.google.maps.LatLng(polyline[point].lat, polyline[point].lng)
              arrival = new this.google.maps.LatLng(polyline[point + 1].lat, polyline[point + 1].lng)
              point += 1
              interval = setInterval(drawLine, timePerStep)
            }
          } else {
            let areWeThereYet = this.google.maps.geometry.spherical.interpolate(departure, arrival, step / numSteps)
            line.setPath([...line.getPath().getArray(), departure, areWeThereYet])
          }
        }

        let interval = setInterval(drawLine, timePerStep)
      }
    }
  },
  errorCaptured () {
    // Prevents errors propagating when they are the result of map being unmounted before promise completes
    if (!this.$refs['vue-map']) return false
  },
  created () {
    // Allow any Vue component to directly interact with map via callback
    this.$root.$on('setMap', (cb) => {
      this.$refs.map.$mapPromise.then(map => {
        cb(map)
      })
    })
  },
  mounted () {
    // Don't try to load the map in automated tests
    if (environment === 'test') return

    this.$store.dispatch('ondemand/setLocation', { field: 'origin', location: null })
    this.$store.dispatch('ondemand/setLocation', { field: 'destination', location: null })
    this.$store.dispatch('map/resetMarkers')
    this.$store.dispatch('geolocate/get')
  },
  methods: {
    clickMarker (marker) {
      const i = this.markers.findIndex(thisMarker => thisMarker === marker)
      this.$store.dispatch('discovery/setSelected', { id: `card-${i}`, ...marker.position })
    },
    getCenterAsJson () {
      return this.$refs.map.$mapPromise.then((map) => map.center.toJSON())
    },
    getCenterAsUrlValue () {
      return this.$refs.map.$mapPromise.then((map) => map.center.toUrlValue())
    },
    handlePolygonClick (event) {
      const { latLng } = event
      if (this.selectionMode === 'origin') {
        this.$store.dispatch('ondemand/setLocation', { field: 'origin', location: { latLng } })
        this.$store.dispatch('ondemand/stashProp', { path: 'selectionMode', value: 'destination' })
      } else {
        this.$store.dispatch('ondemand/setLocation', { field: 'destination', location: { latLng } })
      }
    },
    setBounds () {
      if (environment === 'test') return
      if (this.origin && this.destination) {
        const bounds = new this.google.maps.LatLngBounds()
        bounds.extend(this.origin.latlng)
        bounds.extend(this.destination.latlng)
        const padding = window.innerWidth > 768
          ? { bottom: 300, right: 300, top: 100 }
          : { top: 0, bottom: 300 }
        this.$refs.map.$mapPromise.then((map) => map.fitBounds(bounds, padding))
      } else if (this.origin && !this.destination) {
        this.$refs.map.$mapPromise.then((map) => {
          map.panTo(this.origin.latlng)
          map.setZoom(15)
        })
      } else if (this.destination && !this.origin) {
        this.$refs.map.$mapPromise.then((map) => {
          map.panTo(this.destination.latlng)
          map.setZoom(15)
        })
      } else {
        this.$refs.map.$mapPromise.then((map) => {
          map.panTo(this.mapMarkerCoords)
          map.setZoom(15)
        })
      }
    },
    getIcon (type) {
      if (type.context) {
        return 'https://api.staging.mobilleo.net/icon/marker/w3w.svg?width=24&height=36&color=%23e11f26'
      } else {
        return require('assets/markers/map-marker@0.5x.png')
      }
    }
  }
}
</script>
